$mobileVersion: 480px;

.terms-policies {
	&-underline {
		color: #ffce0a;
		border-top: 4px solid #ffce0a;
	}
}

.terms-and-policy {
	@media (min-width: 480px) {
		height: 70vh;
		padding: 100px 90px 120px 90px;
	}
	@media (max-width: 479px) {
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 25px;
	}
	&-column {
		font-size: 26px;
		font-weight: 600;
		margin-bottom: 20px;
		text-decoration: none;
		font-family: "Poppins", sans-serif;
		color: black;
		&-hr {
			color: rgba(255, 206, 10, 255) !important;
			border: 5px solid rgba(255, 206, 10, 255);
		}
	}
	&-column:hover {
		text-decoration: none;
		color: #ffce0a;
	}
}

.listing-detail-sec1 {
	background-image: url("../../public/images/yellowish_landing_paralax.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 130px 90px 130px 90px;

	@media (max-width: $mobileVersion) {
		padding: 40px 20px;
	}
	.listing-detail-heading {
		font-family: "Poppins", sans-serif;
		font-size: 52px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 1.2em;
		@media (max-width: $mobileVersion) {
			font-size: 33px;
		}
	}
	.bottom-line {
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 5px;
		position: relative;
		width: 8.5%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		@media (max-width: $mobileVersion) {
			margin-top: 8px;
			margin-bottom: 12px;
			padding: 3px;
			width: 15.5%;
		}
	}
	.listing-detail-paragraph {
		margin: 0% 44% 0% 0%;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		text-align: start;
		letter-spacing: normal;
		@media (max-width: $mobileVersion) {
			font-size: 10px;
			margin: 0%;
			line-height: 1.7;
		}
	}
}

.policy-header {
	background-image: url("../../public/images/yellowish-banner-bw-1.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	.policy {
		padding: 130px 90px 130px 90px;
		@media (max-width: $mobileVersion) {
			padding: 40px 20px;
		}
		&-heading {
			font-family: "Poppins", sans-serif;
			font-size: 52px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 1.2em;
			@media (max-width: $mobileVersion) {
				font-size: 33px;
			}
		}
		&-line {
			margin-top: 20px;
			margin-bottom: 20px;
			height: 8px;
			position: relative;
			width: 50px;
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
			@media (max-width: $mobileVersion) {
				height: 5px;
			}
		}
		&-paragraph {
			width: 44%;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			text-align: start;
			letter-spacing: normal;
			@media (max-width: $mobileVersion) {
				width: 100%;
				font-size: 10px;
				line-height: 140%;
			}
		}
	}
}

@media all {
	table {
		background-color: transparent;
		width: 100%;
		margin-bottom: 15px;
		font-size: 0.9em;
		border-spacing: 0;
		border-collapse: collapse;
	}
	table td,
	table th {
		padding: 15px;
		line-height: 3;
		vertical-align: top;
		border: 1px solid #ccc;
	}
	table th {
		font-weight: 700;
	}
	table tbody > tr:nth-child(odd) > td,
	table tbody > tr:nth-child(odd) > th {
		background-color: #f7f7f7;
	}
	table tbody tr:hover > td,
	table tbody tr:hover > th {
		background-color: #e9e9e9;
	}
}
@media all {
	.auto-listings .auto-listings-dashboard .table {
		margin-bottom: 30px;
		max-width: 100%;
		width: 100%;
		text-align: left;
	}
}
@media all {
	table {
		margin: 0 0 1.5em;
		width: 100%;
		font-size: 14px;
		font-size: 0.875rem;
		border-collapse: collapse;
		border-spacing: 0;
	}
	th {
		font-weight: 400;
		color: #000;
	}
	td {
		color: #777780;
	}
	th,
	td {
		text-align: center;
		padding: 26px 5px;
		border: 1px solid #e2e2e2;
	}
}
/*! CSS Used from: Embedded */
@media screen and (max-width: 568px) {
	.user_listing {
		width: 300px;
		overflow: auto;
	}
	.user_listing table {
		word-break: initial !important;
	}
}

//****************************************************************************************************
//****************************************************************************************************
//****************************************** Listing Details *****************************************
//****************************************************************************************************
//****************************************************************************************************

@media all {
	button,
	input,
	textarea {
		font-family: "Open Sans", sans-serif;
	}

	// input[type="text"],
	textarea {
		color: #666;
		border: 1px solid #e4e4e4;
		border-radius: 3px;
		padding: 3px;
	}
	// input[type="text"]:focus,
	.auto-listings-single .sidebar > div {
		padding: 16px 30px 30px;
		border-bottom: 1px solid #e4e4e4;
	}
	.auto-listings-single .sidebar > div:last-child {
		border-bottom: 0;
	}
	.auto-listings-single .sidebar h3 {
		font-size: 16px;
		font-size: 1rem;
		font-weight: 700;
		color: #000;
		position: relative;
		font-family: "Open Sans", sans-serif;
		color: #000;
	}
	.auto-listings-single .sidebar h3:before {
		content: "";
		border-left: 10px solid #ffce0a;
		width: 10px;
		height: 30px;
		left: -40px;
		position: absolute;
	}
	.auto-listings-single .sidebar .contact-form input[type="text"],
	.auto-listings-single .sidebar .contact-form textarea {
		font-size: 13px;
		font-size: 0.8125rem;
		font-style: italic;
		color: #aaaaaa;
		padding: 15px 15px;
		margin-bottom: 0px;
	}
	.auto-listings-single .sidebar .contact-form textarea {
		min-height: 115px;
	}

	.auto-listings-single .sidebar {
		width: 100%;
	}
	.auto-listings-single .sidebar > div {
		margin-bottom: 0;
	}
}

@media all {
	.rwmb-field {
		display: flex;
	}
	.rwmb-field:not(:last-of-type) {
		margin: 0 0 12px;
	}
	.rwmb-input {
		flex: 1;
	}
	.rwmb-input input:not([size]),
	.rwmb-input textarea:not([cols]) {
		width: 100%;
	}
	.rwmb-input input,
	.rwmb-input textarea {
		max-width: 100%;
	}
	.rwmb-textarea {
		resize: vertical;
	}
	.rwmb-text {
		color: #000 !important;
	}
	@media (max-width: 782px) {
		.rwmb-field {
			flex-direction: column;
		}
	}
}

// ************************************************ USER LISTING AND PACKAGE TABLE ************************************ //

@media all {
	::-ms-reveal {
		display: none;
	}
}
@media all {
	*,
	:after,
	:before {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	@media print {
		*,
		:after,
		:before {
			background: transparent !important;
			color: #000 !important;
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
		tr {
			page-break-inside: avoid;
		}
	}
	user_table {
		background-color: transparent;
		width: 100%;
		margin-bottom: 15px;
		font-size: 0.9em;
		border-spacing: 0;
		border-collapse: collapse;
	}
	user_table td,
	user_table th {
		padding: 15px;
		line-height: 1.5;
		vertical-align: top;
		border: 1px solid #ccc;
	}
	user_table th {
		font-weight: 700;
	}
	user_table tbody > tr:nth-child(odd) > th {
		background-color: #f7f7f7;
	}
	user_table tbody tr:hover > td,
	user_table tbody tr:hover > th {
		background-color: #e9e9e9;
	}
}

@media all {
	.auto-listings .auto-listings-dashboard .user_table {
		margin-bottom: 30px;
		max-width: 100%;
		width: 100%;
		text-align: left;
	}
	.listing-table-button {
		border: 0px;
		background-color: #fbce0a;
		height: 45px;
		border-radius: 5px;
		width: 100%;
		font-size: 12px;
	}
}

@media all {
	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
	user_table {
		margin: 0 0 1.5em;
		width: 100%;
		font-size: 14px;
		font-size: 0.875rem;
		border-collapse: collapse;
		border-spacing: 0;
	}
	th {
		font-weight: 400;
		color: #000;
	}
	td {
		color: #777780;
	}
	th,
	td {
		text-align: center;
		padding: 26px 5px;
		border: 1px solid #e2e2e2;
	}
}
