@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;900&display=swap");
$basic-white: #ffffff;
$yellow900: #ffce0a;
$black900: #000000;
$black800: #2b2e34;
$black700: #8c8d90;
$red: #c02b0a;
$mobileVersion: 480px;
hr {
	margin-top: 0;
	margin-bottom: 0;
}
html,
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Poppins", sans-serif !important;
	margin: 0px;
}
p {
	font-family: "Poppins", sans-serif !important;
	margin-bottom: 0px;
}

.w-10 {
	width: 10%;
}

.color-red {
	color: $red;
}
.position-relative {
	position: relative;
}
.padding-adjust {
	margin-right: 20px;
	width: 100%;
	padding-top: 40px;
	padding-bottom: 55px;
	@media (max-width: $mobileVersion) {
		width: 80%;
		margin-bottom: 15px;
		padding-top: 30px;
		padding-bottom: 30px;
	}
}
.box-shadow {
	box-shadow: 0 0 9px -6px #000;
	border-radius: 27px;
}
.no-padding {
	padding-left: 0;
	padding-right: 0;
}
.no-margin {
	margin-left: 0px;
	margin-right: 0px;
}
.bold {
	font-weight: bold;
}
.arrow {
	width: 20px;
	padding-left: 4px;
}
.content {
	display: none;
	position: absolute;
	background-color: $basic-white;
	min-width: 170px;
	z-index: 1;
	top: 45px;
}

.content .other {
	color: #2b2e34;
	font-size: 13px;
	font-weight: 500;
	padding: 13px 35px 13px 28px;
	text-decoration: none;
	display: block;
	transition: 0.3s;
	border-bottom: 1px solid #ececec;
}

.content .other:hover {
	background-color: $yellow900;
}
.content {
	display: none;
	position: absolute;
	background-color: $basic-white;
	min-width: 170px;
	z-index: 1;
	top: 45px;
}

.content .other {
	color: #2b2e34;
	font-size: 13px;
	font-weight: 500;
	padding: 13px 35px 13px 28px;
	text-decoration: none;
	display: block;
	transition: 0.3s;
	border-bottom: 1px solid #ececec;
}

.content .other:hover {
	background-color: $yellow900;
}

.dropdown:hover .content {
	display: block;
}
.text-alig {
	display: flex;
	align-items: center;
	justify-content: center;
}
.text-row {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-width: $mobileVersion) {
		flex-direction: row;
		margin-bottom: 30px;
	}
}
.margin-bottom {
	margin-bottom: 111px;
	@media (max-width: $mobileVersion) {
		margin-bottom: 0px;
	}
}

.text-align {
	align-items: center;
	justify-content: center;
	flex-direction: column;
	display: flex;
}
.cursor-pointer {
	cursor: pointer;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-20 {
	margin-bottom: 20px;
}
.bg-yellow900 {
	background-color: $yellow900;
}
.bg-black900 {
	background-color: $black900;
}
.bg-black800 {
	background-color: $black800;
}
.bg-black700 {
	background-color: $black700;
}
.bg-white {
	background-color: $basic-white;
}
.color-white {
	color: $basic-white;
}
.color-black900 {
	color: $black900;
}
.color-black800 {
	color: $black800;
}
.color-black700 {
	color: $black700;
}
.color-yellow900 {
	color: $yellow900;
}
.border-white {
	border: 1px solid $basic-white;
}
.border-yellow900 {
	border: 1px solid $yellow900;
}
.align-all-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.overlay {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
}
@keyframes fade {
	from {
		opacity: 0.4;
	}
	to {
		opacity: 1;
	}
}
.flex-end {
	display: flex;
	justify-content: flex-end;
}
.show-on-mobile {
	display: none;
	@media (max-width: 1024px) {
		display: block;
	}
}
.hide-on-mobile {
	display: block;
	@media (max-width: 1024px) {
		display: none !important;
	}
}
.logo-width {
	@media (min-width: 768px) {
		width: 20%;
	}
	@media (max-width: 1024px) and (min-width: 768px) {
		width: 30%;
	}
}
.links-width {
	position: relative;
	display: flex;
	@media (min-width: 768px) {
		width: 80%;
	}
	// @media (max-width: 1024px) and (min-width: 768px) {
	// 	width: 30%;
	// }
}

// .add-listing-label {
// 	font-weight: "bold" !important;
// 	color: "#777777" !important;
// }

.container-width {
	max-width: 1560px !important;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	position: relative;
}
.header {
	background-color: $black900;
	padding: 20px;
	position: relative;
	z-index: 100;
	&.responsive {
		@media (max-width: 600px) {
			position: relative;
		}
	}
	@media (max-width: 600px) {
		padding: 10px 20px 20px 5px;
	}
	.icon {
		display: none;
		@media (max-width: 1024px) {
			display: block;
		}
	}
	.special-class {
		display: none;
		@media (max-width: 600px) {
			display: none;
		}
	}
	.navbar {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		padding: 0px;
		@media (max-width: 600px) {
			padding: 0px;
		}
	}
	&-logo-container {
		padding: 10px;
		.logo {
			width: 100%;
			max-width: 250px;
		}
	}
	&-links {
		margin: 0% 0% 0% 5%;
		@media (max-width: 600px) {
			margin-left: 0px;
		}
	}
	&-padding {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		flex-wrap: wrap;
		@media (max-width: 600px) {
			flex-direction: column;
			padding: 0px;
		}
	}
	&.responsive .special-class {
		.link-class {
			padding: 13px 0px;
			border: 1px solid #e4e4e4;
			font-family: "Poppins", Sans-serif;
			font-size: 15px;
			text-transform: uppercase;
			font-weight: 500;
			color: $black900;
			border-bottom: 4px solid transparent;
			transition: 0.3s;
			width: 100%;
			text-align: center;
			&:hover {
				border-bottom: 4px solid $yellow900;
				text-decoration: none;
				color: $yellow900;
			}
			&-login {
				padding: 13px 0px;
				margin: 0px 10px;
				width: 100%;
				border: 1px solid #e4e4e4;
				font-family: "Poppins", Sans-serif;
				font-size: 15px;
				text-transform: uppercase;
				font-weight: 500;
				color: $black900;
				transition: 0.3s;
			}
		}
		@media (max-width: 600px) {
			background-color: $basic-white;
			display: block;
			position: absolute;
			top: 150px;
			left: 8px;
			right: 0px;
			max-width: 100% !important;
		}
	}
	.login {
		display: flex;
		align-items: center;
		justify-content: center;
		&-link {
			padding: 13px 0px;
			margin: 0px 10px;
			font-family: "Poppins", Sans-serif;
			font-size: 15px;
			text-transform: uppercase;
			font-weight: 500;
			color: $basic-white;
			border-bottom: 4px solid transparent;
			transition: 0.3s;
			&:hover {
				border-bottom: 4px solid $yellow900;
				color: $yellow900;
				text-decoration: none;
			}
		}
	}
	.link {
		padding: 13px 0px 9px 0px;
		margin: 0px 15px;
		font-family: "Poppins", Sans-serif;
		font-size: 15px;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 400;
		color: $basic-white;
		border-bottom: 4px solid transparent;
		transition: 0.3s;
		&:hover {
			border-bottom: 4px solid $yellow900;
			text-decoration: none;
			color: $yellow900;
		}
	}
	.dropdown {
		display: flex;
	}
	.content {
		display: none;
		position: absolute;
		background-color: $basic-white;
		min-width: 170px;
		z-index: 1;
		top: 45px;
	}

	.content .other {
		color: #2b2e34;
		font-size: 13px;
		font-weight: 500;
		padding: 13px 35px 13px 28px;
		text-decoration: none;
		display: block;
		transition: 0.3s;
		border-bottom: 1px solid #ececec;
	}

	.content .other:hover {
		background-color: $yellow900;
	}

	.dropdown:hover .content {
		display: block;
	}

	.active-link {
		border-bottom: 4px solid $yellow900;
	}
	.empty {
		display: flex;
	}
}

.bg-overlay {
	background-color: transparent;
	opacity: 0.75;
	background-image: linear-gradient(180deg, #00000080 0%, #000 100%);
	transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.home-hero-section {
	position: static;
	padding: 100px 0px;
	background-size: 1330px;
	background-color: black;
	// background-image: url("/images/yellowish-comingsoon-banner2-1024x373.jpg");
	// background-repeat: no-repeat;
	// background-position: 45% 12%;
	.slideshow-container {
		position: absolute;
		margin: auto;
		width: 100%;
		& .fade-img {
			animation-name: fade;
			animation-duration: 1.5s;
		}
	}
	.home-hero-background-overlay {
		background-color: transparent;
		opacity: 0.75;
		background-image: linear-gradient(180deg, #00000080 0%, #000 100%);
		transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	}
	.logo-hero-section {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 400px;
		.hero-img {
			text-align: center;
			max-width: 500px;
			width: 100%;
			// margin-bottom: -55px;
			padding: 0;
		}
	}
	.heading {
		font-size: 28px;
		font-weight: 500;
		width: 90%;
		text-transform: uppercase;
		line-height: 1.3em;
		color: $yellow900;
		padding-top: 40px;
		text-align: center;
		margin-bottom: 10px;
	}
	.description-1 {
		text-align: center;
		letter-spacing: normal;
		color: $basic-white;
		font-size: 24px;
		font-weight: 500;
		line-height: 1.3em;
	}
	.description-secondary-text {
		margin-top: 50px;
		.description-2 {
			font-size: 15px;
			line-height: 25.5px;
			text-align: center;
			letter-spacing: normal;
			color: $basic-white;
		}
	}
}

.advertisement {
	padding: 90px 0px;
	.heading {
		margin-bottom: 20px;
		font-size: 1.5rem;
		text-align: center;
		font-weight: 700;
		text-transform: uppercase;
		color: $yellow900;
		line-height: 1.2em;
	}
	.slideshows {
		position: relative;
		margin: auto;
		.ads {
			width: 100%;
			align-items: center;
			justify-content: center;
			.images {
				width: 90%;
			}
		}
		& .fade-img {
			animation-name: fade;
			animation-duration: 1.5s;
		}
		.prev {
			position: absolute;
			top: 50%;
			width: auto;
			padding: 16px;
			margin-top: -22px;
			color: $black800;
			font-weight: bold;
			font-size: 18px;
			transition: 0.6s ease;
			border-radius: 0 3px 3px 0;
			user-select: none;
			&:hover {
				text-decoration: none;
			}
		}
		.next {
			position: absolute;
			top: 50%;
			right: 0;
			border-radius: 3px 0 0 3px;
			width: auto;
			padding: 16px;
			margin-top: -22px;
			color: $black800;
			font-weight: bold;
			font-size: 18px;
			transition: 0.6s ease;
			border-radius: 0 3px 3px 0;
			user-select: none;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
.flickity-page-dots {
	display: none;
}
.flickity-button {
	background: transparent !important;
}
.flickity-prev-next-button.next {
	right: -50px !important;
}
.flickity-prev-next-button.previous {
	left: -50px !important;
}
.w3-image {
	width: 100% !important;
}
.section-3 {
	background-color: $yellow900;
	padding-top: 120px;
	padding-bottom: 90px;
	.heading {
		font-weight: 700;
		font-size: 36px;
		text-transform: uppercase;
		line-height: 1.2em;
		color: $black900;
	}
}
.selling-box {
	background-image: url("../../public/images/yellowish_landing_paralax.jpg");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center center;
	background-size: cover;
	padding: 90px 0px;
	@media (max-width: $mobileVersion) {
		padding: 0px;
	}
	.right {
		border-right: 4px solid $yellow900;
		@media (max-width: $mobileVersion) {
			border-right: none;
		}
	}
	.left {
		border-left: 4px solid $yellow900;
		@media (max-width: $mobileVersion) {
			border-left: none;
		}
	}
}
.button-arrange {
	text-align: center;
}
.selling {
	padding: 40px;
	&-line {
		@media (max-width: $mobileVersion) {
			border-bottom: 4px solid $yellow900;
		}
	}
	&-arrange {
		display: flex;
		padding-top: 90px;
		margin-top: -10px;
		margin-bottom: 90px;
		justify-content: center;
		align-items: center;
	}
	&-heading {
		font-weight: 700;
		text-transform: uppercase;
		font-size: 30px;
		line-height: 1.2em;
		letter-spacing: normal;
		color: $basic-white;
	}
	&-description {
		padding: 21px 52px 41px 52px;
		font-size: 14px;
		line-height: 20.4px;
		letter-spacing: normal;
		color: $basic-white;
	}
	&-button {
		line-height: 1;
		font-size: 15px;
		padding: 12px 24px;
		color: $black900;
		text-decoration: none;
		background-color: $yellow900;
		font-weight: normal;
		text-transform: uppercase;
		border: 1px solid $yellow900;
		border-radius: 30px;
		transition: all 0.3s;
		&:hover {
			color: white;
			transition: 0.3s;
			text-decoration: none;
		}
	}
}

.section-4 {
	padding: 90px 120px;
	.image-ads {
		height: 260px;
		width: 100%;
	}
	.slideshows {
		position: relative;
		margin: auto;
		.ads1 {
			width: 100%;
			align-items: center;
			justify-content: center;
			.images {
				width: 90%;
			}
			.images {
				width: 90%;
				height: 55%;
			}
		}
		& .fade-img1 {
			animation-name: fade;
			animation-duration: 1.5s;
		}
		.prev {
			position: absolute;
			top: 50%;
			width: auto;
			padding: 16px;
			margin-top: -22px;
			color: $black800;
			font-weight: bold;
			font-size: 18px;
			transition: 0.6s ease;
			border-radius: 0 3px 3px 0;
			&:hover {
				text-decoration: none;
			}
		}
		.next {
			position: absolute;
			top: 50%;
			right: 0;
			border-radius: 3px 0 0 3px;
			width: auto;
			padding: 16px;
			margin-top: -22px;
			color: $black800;
			font-weight: bold;
			font-size: 18px;
			transition: 0.6s ease;
			border-radius: 0 3px 3px 0;
			user-select: none;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.section-5 {
	.flex {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.quotation-box {
		padding: 70px 60px;
		height: 400px;
		max-height: 450px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		.heading {
			text-align: center;
			font-size: 35px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 1.2em;
			.heading-span {
				font-size: 26px;
			}
		}
		.description {
			text-align: center;
			font-size: 14px;
			line-height: 1.7;
			margin-top: 20px;
			font-weight: 400;
			margin-bottom: 30px;
			width: 80%;
		}
		.quotation-index {
			z-index: 3;
			position: relative;
		}
		.finance-button {
			text-decoration: none;
			line-height: 1;
			font-size: 15px;
			padding: 12px 24px;
			text-transform: uppercase;
			border-radius: 30px 30px 30px 30px;
			transition: 0.3s;
			font-weight: 500;
		}
		.finance-button1 {
			text-decoration: none;
			line-height: 1;
			font-size: 15px;
			padding: 12px 24px;
			text-transform: uppercase;
			border-radius: 30px 30px 30px 30px;
			transition: 0.3s;
			font-weight: 500;
		}
		.finance-button1:hover {
			color: $yellow900;
		}
		.finance-button:hover {
			color: $basic-white;
		}
		.finance-button.bg-white:hover {
			background-color: $black900;
		}
		.fin-button:hover {
			color: $yellow900;
			transition: 0.3s;
		}
	}
	.layout {
		background-color: $black800;
	}
	.layout-light-black {
		background-color: $black700;
	}
	.layout-yellow {
		background-color: #ffce0a;
	}
	.quotation-bg-size {
		background-position: center center;
		background-repeat: repeat;
		background-size: 45% auto;
		width: 100%;
		opacity: 0.2;
	}
	.layout-1 {
		background-image: url("../../public/images/hand-finance-money.svg");
	}
	.layout-2 {
		background-image: url("../../public/images/delivery-truck.svg");
	}
	.layout-3 {
		background-color: #8c8d90;
		background-image: url("../../public/images/construction-mixer.svg");
	}
}

.footer-main {
	background-color: $black900;
	background-attachment: fixed;
	padding-top: 100px;
	.links-main {
		margin-bottom: 40px;
		.links-heading {
			font-size: 15px;
			font-weight: 700;
			text-transform: uppercase;
			color: #ffce0a;
			line-height: 1.2em;
		}
		.links-box {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
		}
		.chevron-right {
			width: 14px;
		}
		.links-tab {
			margin-left: 10px;
			text-decoration: none;
		}
		.links {
			font-size: 14px;
			line-height: 21px;
			text-align: left;
			letter-spacing: normal;
			font-weight: 400;
		}
		.links-tab:hover {
			text-decoration: none;
		}
	}
	.footer-heading {
		padding-top: 25px;
		padding-bottom: 25px;
		color: white;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 1.2em;
		margin: 0;
		text-align: left;
	}
	.footer-logo {
		width: 185px;
		height: 73.88px;
	}
	.footer-img {
		width: 20px;
		margin-right: 5px;
	}
	.subscribe-btn {
		border-radius: 30px;
		outline: none;
		padding: 10px 26px;
		text-transform: uppercase;
		font-size: 14px;
		font-family: "Poppins", sans-serif;
		margin-top: 15px;
		font-weight: 500;
		transition: 0.3s;
	}
	.subscribe-btn:hover {
		background-color: $yellow900;
		color: $basic-white;
	}
	.newsletter {
		width: 70%;
	}
	.copyright-box {
		padding-top: 40px;
		padding-bottom: 20px;
		@media (max-width: $mobileVersion) {
			padding-top: 0px;
			padding-bottom: 0px;
		}
		.copyright-text {
			text-align: center;
			font-size: 12px;
			font-weight: 600;
			margin-bottom: 0px;
			@media (max-width: $mobileVersion) {
				font-size: 10px;
				padding: 11px;
			}
		}
	}
}
.footer-space {
	margin-top: 80px;
	@media (max-width: $mobileVersion) {
		margin-top: 27px;
	}
}

@media (max-width: $mobileVersion) {
	.home-hero-section {
		.heading {
			font-size: 16px;
		}
		.description-1 {
			font-size: 15px;
		}
		.description-secondary-text {
			margin-top: 25px;
			.description-2 {
				font-size: 12px;
				margin-bottom: 15px;
			}
		}
	}
	.advertisement {
		padding: 20px 0px;
		.heading {
			font-size: 16px;
		}
	}
	.carousel-cell {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.w3-image {
		width: 90% !important;
	}
	.flickity-prev-next-button.previous {
		left: -10px !important;
	}
	.flickity-prev-next-button {
		width: 30px !important;
		height: 30px !important;
	}
	.flickity-prev-next-button.next {
		right: -10px !important;
	}
	.section-3 {
		padding-top: 35px;
		padding-bottom: 30px;
		.heading {
			font-size: 24px;
		}
	}
	// .selling {
	//   padding: 0px;
	//   .right {
	//     border-right: none;
	//   }
	//   .left {
	//     border-left: none;
	//   }
	//   .selling-box {
	//     border-bottom: 4px solid $yellow900;
	//     .heading {
	//       font-size: 24px;
	//     }
	//     .description {
	//       padding: 20px;
	//       font-size: 12px;
	//     }
	//     .selling-button {
	//       font-size: 12px;
	//       transition: 0.3s;
	//     }
	//   }
	//   .last-one {
	//     border-bottom: none !important;
	//   }
	// }
	.section-4 {
		padding: 20px;
		.last-one {
			margin-top: 20px;
		}
	}

	.section-5 {
		.quotation-box {
			padding: 50px 0px;
			height: 280px;
			max-height: 300px;
			.heading {
				font-size: 24px;
				.heading-span {
					font-size: 16px;
				}
			}
			.description {
				font-size: 14px;
				width: 80%;
			}
		}
	}
	.footer-main {
		padding-top: 42px;
		.footer-logo {
			margin-bottom: 40px;
		}
		.footer-heading {
			display: none;
		}
		.hide-on-mobile {
			display: none;
		}
		.special-width {
			width: 50%;
		}
		.newsletter {
			width: 100%;
		}
		.subscribe-btn {
			font-size: 12px;
		}
		.links-main {
			.links-heading {
				font-size: 13px;
			}
			.links {
				font-size: 12px;
			}
			.chevron-right {
				width: 12px;
			}
		}
	}
}

.selling-home-section {
	background-image: url("../../public/images/yellowish-banner-bw-2.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	.sell {
		padding: 130px 90px 130px 90px;
		@media (max-width: $mobileVersion) {
			padding: 40px 20px;
		}
		&-heading {
			font-family: "Poppins", sans-serif;
			font-size: 52px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 1.2em;
			@media (max-width: $mobileVersion) {
				font-size: 33px;
			}
		}
		&-line {
			margin-top: 20px;
			margin-bottom: 20px;
			height: 8px;
			position: relative;
			width: 50px;
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
			@media (max-width: $mobileVersion) {
				height: 5px;
			}
		}
		&-paragraph {
			width: 44%;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			text-align: start;
			letter-spacing: normal;
			@media (max-width: $mobileVersion) {
				width: 100%;
				font-size: 10px;
				line-height: 140%;
			}
		}
	}
}
.sell-sec2 {
	&-heading1 {
		font-weight: 600;
		line-height: 1.2em;
		font-size: 1.25rem;
		padding-bottom: 20px;
		@media (max-width: $mobileVersion) {
			font-size: 14px;
			line-height: 1.2em;
			padding-bottom: 10px;
		}
	}
	&-heading2 {
		font-weight: 600;
		line-height: 1.2em;
		font-size: 1.375rem;
		@media (max-width: $mobileVersion) {
			font-size: 16px;
			line-height: 1.2em;
		}
	}
	&-bottom-line {
		margin-top: 20px;
		padding: 3.5px;
		position: relative;
		width: 33.5%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}

	&-padd {
		@media (max-width: $mobileVersion) {
			padding: 20px 20px 40px 20px;
		}
	}
	&-align {
		text-align: center;
		font-weight: 600;
		line-height: 33.6px;
		font-size: 28px;
		padding-top: 100px;
		padding-bottom: 50px;
		@media (max-width: $mobileVersion) {
			font-size: 18px;
			padding-right: 59px;
			padding-left: 60px;
			padding-top: 0px;
			line-height: 1.2;
			padding-bottom: 35px;
		}
	}
	&-paragraph {
		margin-top: 60px;
		font-size: 14px;
		line-height: 1.7;
		margin-bottom: 1.5em;
		text-align: center;
		font-weight: 400;
		@media (max-width: $mobileVersion) {
			font-size: 13px;
			margin-top: 25px;
		}
	}
	&-button {
		text-align: center;
		display: inline-block;
		border-radius: 30px 30px 30px 30px;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 1;
		border-style: solid;
		border-width: 2px 2px 2px 2px;
		padding: 15px 30px;
		width: auto;
		transition: 0.3s;
		@media (max-width: $mobileVersion) {
			font-size: 12px;
			padding: 10px 20px;
		}
	}
	&-button:hover {
		background-color: white;
		color: #ffce0a;
	}
	&-justify {
		text-align: justify;
		&::after {
			content: "";
			width: 100%;
			display: inline-block;
		}
	}
}

#quote-sec-1 {
	background-image: url("../../public/images/yellowish-banner-bw-4.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 130px 90px 130px 90px;
	.quote-heading {
		font-family: "Poppins", sans-serif;
		font-size: 52px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 1.2em;
	}
	.bottom-line {
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 5px;
		position: relative;
		width: 8.5%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}
	.quote-paragraph {
		margin: 0% 44% 0% 0%;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		text-align: start;
		letter-spacing: normal;
	}
}

.cont1 {
	padding-top: 60px;
	@media (max-width: $mobileVersion) {
		padding-top: 20px;
	}
	&-logo {
		width: 185px;
		height: 73.88px;
		@media (max-width: $mobileVersion) {
			display: none;
		}
	}
	&-image {
		width: 20px;
		margin-right: 5px;
	}
	&-textalign {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}
	&-heading1 {
		padding-bottom: 32px;
		font-size: 45px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 1.2em;
		padding-top: 85px;
		@media (max-width: $mobileVersion) {
			font-size: 26px;
		}
	}
	&-anker {
		padding-bottom: 2px;
		cursor: pointer;
		text-decoration: none;
		font-size: 16px;
		font-weight: 600;
		line-height: 1;
	}
	&-anker1 {
		cursor: pointer;
		text-decoration: none;
		font-size: 16px;
		font-weight: 600;
		line-height: 1;
	}
	&-anker:hover {
		color: white;
		text-decoration: none;
	}
	&-anker1:hover {
		color: #ffce0a;
		text-decoration: none;
	}
	&-padding {
		padding: 80px;
		@media (max-width: $mobileVersion) {
			padding: 40px 30px 40px 30px;
			align-content: center;
			align-items: center;
		}
	}
	&-padd {
		padding-top: 25px;
		padding-bottom: 55px;
		@media (max-width: $mobileVersion) {
			padding-bottom: 10px;
		}
	}
	&-bgcontact {
		background-image: url("../../public/images/contact_Us_Image_comp-scaled.jpeg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		@media (max-width: $mobileVersion) {
			background-image: none;
		}
	}
	&-button {
		font-weight: 500;
		margin-top: 10px;
		margin-left: 46px;
		text-align: center;
		display: inline-block;
		border-radius: 30px 30px 30px 30px;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 1;
		border-style: solid;
		border-width: 2px 2px 2px 2px;
		padding: 15px 30px;
		width: auto;
		transition: 0.3s;
		@media (max-width: $mobileVersion) {
			font-size: 12px;
			padding: 13px 30px 12px 30px;
			margin-left: 25px;
		}
	}
	&-nopadding {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		@media (max-width: $mobileVersion) {
			padding: 20px 20px 20px 20px;
		}
	}
	&-right {
		right: 21%;
		@media (max-width: $mobileVersion) {
			right: 0px;
		}
	}
	&-flexst {
		margin-left: 46px;
		@media (max-width: $mobileVersion) {
			margin-left: 30px;
		}
	}
	&-attach {
		padding: 40px 10px 40px 0px;
		margin-top: 60px;
		margin-bottom: 60px;
		@media (max-width: $mobileVersion) {
			margin-top: 0px;
			margin-bottom: 0px;
		}
	}
	&-button:hover {
		background-color: white;
		border-color: white;
		color: #ffce0a;
	}
	&-heading2 {
		font-size: 14px;
		padding-bottom: 26px;
		line-height: 1.7;
		font-weight: 700;
		text-transform: uppercase;
	}
	&-para {
		margin-bottom: 21px;
		line-height: 1.7;
		font-size: 12px;
	}
	&-inputBox {
		margin-bottom: 20px;
		padding: 15px 0px 15px 20px;
		width: 82%;
		font-size: 15px;
		float: none;
		height: auto;
		border: 1px solid #e4d4e4;
		@media (max-width: $mobileVersion) {
			padding: 10px 0px 10px 20px;
			font-size: 13px;
			border-radius: 0;
			line-height: 2;
			min-height: 32px;
		}
	}
}

#rental-sec1 {
	background-image: url("../../public/images/yellowish-banner-bw-4.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 130px 90px 130px 90px;

	@media (max-width: $mobileVersion) {
		padding: 40px 20px;
	}
	.rental-heading {
		font-family: "Poppins", sans-serif;
		font-size: 52px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 1.2em;
		@media (max-width: $mobileVersion) {
			font-size: 33px;
		}
	}
	.bottom-line {
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 5px;
		position: relative;
		width: 8.5%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		@media (max-width: $mobileVersion) {
			margin-top: 8px;
			margin-bottom: 12px;
			padding: 3px;
			width: 15.5%;
		}
	}
	.rental-paragraph {
		margin: 0% 44% 0% 0%;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		text-align: start;
		letter-spacing: normal;
		@media (max-width: $mobileVersion) {
			font-size: 10px;
			margin: 0%;
			line-height: 1.7;
		}
	}
}

.rental-sec2 {
	&-align {
		text-align: center;
		font-weight: 600;
		line-height: 33.6px;
		font-size: 28px;
		padding-top: 100px;
		padding-bottom: 50px;
		@media (max-width: $mobileVersion) {
			margin: 40px 40px 40px 40px;
			padding: 40px 40px 40px 40px;
			font-size: 1.75rem;
		}
	}
}

.finq1 {
	padding: 10px 10px 10px 10px;
	display: flex;
	@media (max-width: 600px) {
		display: flex;
		padding: 20px 10px 10px 10px;
		flex-direction: column-reverse;
	}
	&-ads {
		padding: 10px;
		width: 25%;
		@media (max-width: 600px) {
			width: 100%;
			display: flex;
			padding: 5px 7px 5px 5px;
		}
	}
	&-text-area {
		padding: 90px;
		width: 75%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		@media (max-width: 600px) {
			width: 100%;
			padding: 30px;
		}
		.info {
			margin-bottom: 1.5em;
			line-height: 1.7;
			font-size: 14px;
			color: #2b2e34;
			text-align: center;
			@media (max-width: 600px) {
				font-size: 12px;
				margin: 0px 20px;
			}
		}
	}
	.text-box {
		text-align: center;
		width: 100%;
		&-box {
			padding: 0 10% 0 10%;
			margin: 0px 0px 5px 0px;
			@media (max-width: 600px) {
				padding: 0%;
			}
		}
		&-heading {
			color: $black900;
			font-size: 32px;
			font-weight: 600;
			line-height: 1.3em;
			margin: 0px;
			text-align: center;
			@media (max-width: 600px) {
				font-size: 16px;
			}
		}
	}
	.ads-container {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		@media (max-width: 600px) {
			display: flex;
			flex-direction: row;
		}
	}
	.ads-box-2 {
		margin-bottom: 20px;
		@media (max-width: 600px) {
			padding: 5px 7px 5px 5px;
			margin-bottom: 0px;
		}
	}
	&-direction-reverse {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column-reverse;
	}
	&-heading {
		margin-top: 20px;
		vertical-align: middle;
		display: inline-block;
		height: auto;
		width: 100%;
		aspect-ratio: auto 600/600;
		@media (max-width: $mobileVersion) {
			margin: 0;
			padding: 5px 7px 5px 5px;
		}
	}
	&-head {
		padding-bottom: 25px;
		font-size: 32px;
		font-weight: 550;
		line-height: 1.3em;
		text-align: center;
		@media (max-width: $mobileVersion) {
			font-size: 16px;
		}
	}

	&-padding {
		padding: 80px 80px 80px 80px;
		@media (max-width: $mobileVersion) {
			padding: 40px;
		}
	}
	&-para {
		text-align: center;
		font-size: 14px;
		margin-bottom: 1.5em;
		line-height: 1.7;
		font-weight: 400;
		@media (max-width: $mobileVersion) {
			font-size: 12px;
		}
	}
}
.active-class {
	border-top: 3px solid $yellow900 !important;
}
.active-class-2 {
	// background-color: #f4f4f4 !important;
	background-color: $basic-white !important;
	border-top: 3px solid $yellow900 !important;
	border-bottom: 1px solid white !important;
}
.form {
	padding-bottom: 30px;
	.tabs-main {
		width: 100%;
		margin-bottom: 1.618em;
		position: relative;
		padding: 0px 0px 0px 10px;
		.border-line {
			width: 100%;
			&::before {
				border-bottom: 1px solid #dddddd;
				position: absolute;
				bottom: 0px;
				left: 0px;
				width: 100%;
				content: "";
			}
		}
	}
	&-bt1 {
		z-index: 2;
		background-color: #f4f4f4;
		border-bottom: 1px solid #dddddd;
		border-top: 1px solid #dddddd;
		border-right: 1px solid #dddddd;
		border-left: 1px solid #dddddd;
		display: inline-block;
		position: relative;
		padding: 0 1em;
		outline: 0;
		font-size: 100%;
	}
	&-action {
		padding: 0.3em 0px 0.5em 0px;
		font-size: 14px;
		font-family: "Open Sans", sans-serif !important;
		color: #777777;
		font-weight: 700;
	}
	&-head {
		line-height: 1.2em;
		font-size: 1.375rem;
		margin-bottom: 28px;
		font-weight: 500;
		margin-top: 0.5rem;
		font-family: "Open Sans", sans-serif !important;
	}
	&-para {
		font-size: 14px;
		margin-bottom: 1.5em;
		line-height: 1.7;
		margin-top: 0%;
		color: #777777;
		font-family: "Open-Sans", sans-serif !important;
	}
	&-input {
		border: 1px solid #eaeaeaea;
		font-size: 14px;
		margin: 0 0 5px;
		padding: 8px;
		width: 300px;
		color: #888;
		border-radius: 3px;
		transition: all 0.3s;
		overflow: visible;
	}
	&-bt2 {
		background: #fbce0a none repeat scroll 0 0;
		border: 2px solid #fbce0a;
		border-radius: 0;
		box-shadow: none;
		display: inline-block;
		font-size: 14px;
		height: auto;
		line-height: unset;
		padding: 7px 26px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: normal;
		cursor: pointer;
		transition: all 0.3s;
		margin-bottom: 1.5em;
	}
	&-bt2:hover {
		background-color: white;
	}
	&-inline {
		display: inline-block;
		width: 180px;
	}
}

.transport-sec1 {
	background-image: url("../../public/images/yellowish-banner-bw-4.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 130px 90px 130px 90px;

	@media (max-width: $mobileVersion) {
		padding: 40px 29px;
	}
	.transport-heading {
		font-family: "Poppins", sans-serif;
		font-size: 52px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 1.2em;
		@media (max-width: $mobileVersion) {
			font-size: 33px;
		}
	}
	.bottom-line {
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 5px;
		position: relative;
		width: 8.5%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		@media (max-width: $mobileVersion) {
			margin-top: 8px;
			margin-bottom: 12px;
			padding: 3px;
			width: 15.5%;
		}
	}
	.transport-paragraph {
		margin: 0% 44% 0% 0%;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		text-align: start;
		letter-spacing: normal;
		@media (max-width: $mobileVersion) {
			font-size: 10px;
			margin: 0%;
			line-height: 1.7;
		}
	}
}
.quote-sec1 {
	background-image: url("../../public/images/yellowish-banner-bw-4.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 130px 90px 130px 90px;

	@media (max-width: $mobileVersion) {
		padding: 40px 29px;
	}
	.quote-heading {
		font-family: "Poppins", sans-serif;
		font-size: 52px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 1.2em;
		@media (max-width: $mobileVersion) {
			font-size: 33px;
		}
	}
	.bottom-line {
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 5px;
		position: relative;
		width: 8.5%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		@media (max-width: $mobileVersion) {
			margin-top: 8px;
			margin-bottom: 12px;
			padding: 3px;
			width: 15.5%;
		}
	}
	.quote-paragraph {
		margin: 0% 44% 0% 0%;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		text-align: start;
		letter-spacing: normal;
		@media (max-width: $mobileVersion) {
			font-size: 10px;
			margin: 0%;
			line-height: 1.7;
		}
	}
}

.buying-section {
	padding: 120px 0px 60px 0px;
	.search {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		@media (max-width: $mobileVersion) {
			display: block;
		}
		&-input {
			border-radius: 4px;
			font-size: 15px;
			font-weight: 300;
			border: 1px solid #aaa;
			height: 40px;
			background-color: $basic-white;
			padding: 5px 15px;
			color: #444;
			outline: none;
			flex-grow: 2;
			transition: 0.3s;
			@media (max-width: $mobileVersion) {
				width: 100%;
			}
			&:focus-visible {
				outline: none;
				border-top: 1px solid transparent;
				border-left: 1px solid transparent;
				border-right: 1px solid transparent;
				border-bottom: 1px solid $yellow900;
			}
		}
		&-button {
			background-color: $yellow900;
			padding: 7px 26px;
			text-align: center;
			color: $black900;
			text-transform: uppercase;
			border: 2px solid $yellow900;
			transition: 0.3s;
			font-size: 14px;
			border-radius: 15px;
			margin-left: 5px;
			@media (max-width: $mobileVersion) {
				width: 100%;
				margin-top: 15px;
				margin-bottom: 40px;
			}
		}
	}

	.category-border {
		border: 1px solid #ffce0a;
		padding-top: 40px;
		margin: 5px;
	}
	.display-table {
		display: flex;
		flex-wrap: wrap;
	}
	.listing-main {
		margin-top: 40px;
		width: 100%;
		transition: 0.3s;
	}
	#btnContainer {
		display: flex;
		align-items: flex-end;
		height: 100%;
		@media (max-width: $mobileVersion) {
			display: none;
		}
		.grid-button {
			border: 1px solid #e4e4e4;
			border-radius: 100%;
			padding: 13px 16px;
			margin: 0 7px 0 0;
			cursor: pointer;
			background-color: $basic-white;
		}
	}
	.listing {
		border: 1px solid #e4e4e4;
		display: flex;
		position: relative;
		margin-bottom: 20px;
		@media (max-width: $mobileVersion) {
			display: block;
		}
		&-img {
			width: 39%;
			overflow: hidden;
			@media (max-width: $mobileVersion) {
				width: 100%;
			}
		}
		&-container {
			width: 100%;
		}
		&-image {
			width: 100%;
		}
		.summary {
			transition: 0.3s;
			margin-left: 40px;
			position: relative;
			@media (min-width: 650px) {
				width: 58%;
			}
			@media (max-width: $mobileVersion) {
				margin-left: 0px;
				padding: 0 15px 56px 15px;
			}
			&-title {
				margin-top: 45px;
				font-size: 1.25rem;
				font-weight: 700;
				padding: 15px;
				font-family: "Open Sans", sans-serif !important;
				text-align: left;
				.anchor {
					color: #3d414e;
					text-decoration: none;
					transition: 0.3s;
					&:hover {
						color: $yellow900;
					}
				}
			}
			&-price {
				margin-bottom: 4px;
				font-size: 1.625rem;
				line-height: 1.4;
				color: #3d414e;
				font-weight: 700;
				font-family: "Open Sans", sans-serif !important;
				&-currency {
					margin-right: 5px;
				}
			}
			&-glance {
				position: absolute;
				bottom: 62px;
				right: 224px;
				background-color: #f8f8f8;
				border-right: 1px solid #e4e4e4;
				width: 39%;
				padding: 14px 30px 11px;
				margin-top: 20px;
				@media (max-width: $mobileVersion) {
					position: relative;
					top: revert;
					left: 0px;
					bottom: revert;
					margin-top: 0px;
					width: 100%;
					background-color: transparent;
					border-right: none;
					border-bottom: 1px solid #e4e4e4;
				}
				.box {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 33.33%;
					&-span {
						font-size: 0.75rem;
						color: #555555;
						font-weight: 700;
						text-align: center;
						margin: 0px;
					}
				}
			}
		}
		.contents {
			padding-left: 40px;
			@media (max-width: $mobileVersion) {
				position: relative;
				width: 100%;
				padding-right: 15px;
				padding-left: 15px;
				padding-bottom: 40px;
			}

			.content {
				margin-top: 45px;
				padding: 15px;
				&-title {
					color: #3d414e;
					text-decoration: none;
					font-size: 1.25rem;
					font-weight: 700;
					text-align: left;
					transition: 0.3s;
					&:hover {
						color: $yellow900;
					}
				}
				@media (max-width: $mobileVersion) {
					padding-top: 105px;
					margin-top: 0px;
				}
			}
			&-price {
				margin-bottom: 4px;
				font-size: 1.625rem;
				color: #3d414e;
				font-weight: 700;
				transition: 0.3s;
			}
			&-meter {
				background-color: #f8f8f8;
				border-right: 1px solid #e4e4e4;
				width: 100%;
				padding: 14px 30px 11px;
				margin-top: 20px;
				@media (max-width: $mobileVersion) {
					position: absolute;
					top: 0px;
					left: 0px;
					margin-top: 0px;
					background-color: transparent;
					border-right: none;
					border-bottom: 1px solid #e4e4e4;
				}
				.box {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 33.33%;
					&-span {
						font-size: 0.75rem;
						color: #555555;
						font-weight: 700;
						text-align: center;
						margin: 0px;
					}
				}
			}
		}
		.wrap {
			position: absolute;
			right: 20px;
			bottom: -20px;
			&-button {
				background-color: $yellow900;
				margin-top: 20px;
				transition: 0.3s;
				color: $black800;
				text-transform: uppercase;
				font-size: 0.8125rem;
				font-weight: 700;
				border-radius: 5px;
				border: 1px solid $yellow900;
				padding: 10px 18px;
				margin-right: 14px;
				&:hover {
					background-color: $basic-white;
					text-decoration: none;
				}
			}
		}
		&-new {
			position: absolute;
			left: 10px;
			top: 10px;
			background-color: #5ba533;
			text-transform: uppercase;
			padding: 9px 10px;
			font-size: 0.75rem;
			border-radius: 4px;
			font-weight: 700;
			z-index: 20;
			display: flex;
			align-items: center;
			color: $basic-white;
			.star {
				height: 12px;
				width: 12px;
				margin-right: 5px;
			}
		}
		&-sale {
			position: absolute;
			left: 0px;
			bottom: 0px;
			background-color: $yellow900;
			color: $black900;
			font-size: 12px;
			font-weight: 300;
			padding: 5px 10px;
			margin: 0;
			line-height: 12px;
			&-speed {
				height: 13px;
				width: 13px;
				margin-right: 5px;
			}
		}
	}
	.pagination {
		display: flex;
		align-items: center;
		margin-top: 30px;
		@media (max-width: $mobileVersion) {
			margin-top: 50px;
		}
		&-flex-end {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
		&-btns {
			border: 2px solid #ddd;
			padding: 0.5em 0.75em;
			border-radius: 10px;
			margin-right: 10px;
			font-weight: 500;
			color: $black800;
			font-size: 14px;
			cursor: pointer;
			transition: 0.3s;
			@media (max-width: $mobileVersion) {
				font-size: 11px;
			}
			&:hover {
				color: $basic-white;
				background-color: $yellow900;
				border-color: $yellow900;
				text-decoration: none;
			}
		}
		&-active {
			color: $basic-white;
			background-color: $yellow900;
		}
		&-next {
			cursor: pointer;
			transition: 0.3s;
			padding: 12px 14px;
			border-radius: 50%;
			border: 2px solid #ddd;
			margin-right: 10px;
			&:hover {
				color: $basic-white;
				background-color: $yellow900;
				border-color: $yellow900;
			}
			@media (max-width: $mobileVersion) {
				padding: 10px;
			}
		}
	}
}
.no-decoration:hover {
	text-decoration: none;
}
.category {
	background-color: $basic-white;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: 0.3s;
	margin-bottom: 40px;
	&-circle {
		background-color: $black900;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 120px;
		border-radius: 50%;
		height: 120px;
		transition: 0.3s;
	}
	&-heading {
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 0px;
		color: $yellow900;
		text-align: center;
		margin-top: 20px;
	}
	&-description {
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 0px;
		color: $black900;
		text-align: center;
		margin-top: 10px;
		width: 85%;
	}
	&-button {
		margin-top: 30px;
		border: 1px solid $yellow900;
		background-color: $basic-white;
		padding: 10px 24px;
		border-radius: 35px;
		color: $black900;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		transition: 0.3s;
	}
	&:hover {
		text-decoration: none;
	}
	&:hover &-circle {
		background-color: $yellow900;
	}
	&:hover &-button {
		background-color: $yellow900;
	}
}
.detail {
	width: 100%;
	position: relative;
}
.tables {
	margin-top: 40px;
	&-heading {
		font-size: 1.625rem;
		color: #3d414e;
		font-weight: 700;
		line-height: 1.4;
		text-align: left;
		margin-top: 12px;
	}
	&-info {
		font-size: 12px;
		font-weight: 300;
		line-height: 12px;
		margin-bottom: 1.5rem;
	}
}
.sidebar {
	border: 10px solid #f8f8f8;
	width: 100%;
	&-box {
		padding: 16px 30px 30px;
		width: 100%;
		display: flex;
		&-button {
			width: 100%;
			background-color: $yellow900;
			border: 2px solid #fbce0a;
			padding: 7px 26px;
			color: #000 !important;
			font-size: 14px;
			line-height: unset;
			margin: 0;
			text-align: center;
			transition: 0.3s;
			text-decoration: none;
			&:hover {
				text-decoration: none;
				background-color: $basic-white;
			}
		}
	}
}

.tab {
	overflow: hidden;
	border-bottom: 1px solid #e4e4e4;
	background-color: $basic-white;
	.tabs-class {
		background-color: #f4f4f4;
		border-top: 1px solid #e4e4e4;
		border-bottom: 1px solid transparent;
		border-left: 1px solid #e4e4e4;
		border-right: 1px solid #e4e4e4;
		outline: none;
		cursor: pointer;
		padding: 14px 16px;
		transition: 0.3s;
		font-size: 14px;
		color: $black900;
		font-weight: 600;
		&:hover {
			background-color: #ddd;
		}
	}
	.tabs-class.active {
		background-color: $basic-white;
		border-top: 2px solid $yellow900;
		border-bottom: 1px solid $basic-white;
	}
}

.tabcontent {
	// display: none;
	// padding: 15px 27px;
	// border-bottom: 1px solid #ccc;
	// border-left: 1px solid #ccc;
	// border-right: 1px solid #ccc;
	// border-top: 1px solid transparent;
	.tab-heading {
		font-size: 1.125rem;
		line-height: 2;
		font-weight: 700;
		color: #ffce0a;
		margin-bottom: 28px;
		margin-top: 0, 5rem;
	}
}

.slideshows {
	position: relative;
	margin: auto;
	.ads {
		width: 100%;
		align-items: center;
		justify-content: center;
		.images {
			width: 90%;
		}
	}
	& .fade-img {
		animation-name: fade;
		animation-duration: 1.5s;
	}
	.prev {
		position: absolute;
		top: 50%;
		width: auto;
		padding: 16px;
		margin-top: -22px;
		color: $black800;
		font-weight: bold;
		font-size: 18px;
		transition: 0.6s ease;
		border-radius: 0 3px 3px 0;
		user-select: none;
		&:hover {
			text-decoration: none;
		}
	}
	.next {
		position: absolute;
		top: 50%;
		right: 0;
		border-radius: 3px 0 0 3px;
		width: auto;
		padding: 16px;
		margin-top: -22px;
		color: $black800;
		font-weight: bold;
		font-size: 18px;
		transition: 0.6s ease;
		border-radius: 0 3px 3px 0;
		user-select: none;
		&:hover {
			text-decoration: none;
		}
	}
}

.slideshows-2 {
	position: relative;
	margin: auto;
	height: 100%;
	.ads-tracking {
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		.images-2 {
			width: 100%;
			height: 100%;
		}
	}
	& .fade-img-2 {
		animation-name: fade;
		animation-duration: 1.5s;
	}
	.prev-2 {
		position: absolute;
		top: 50%;
		width: auto;
		padding: 12px;
		margin-top: -32px;
		color: #7777;
		font-weight: bold;
		font-size: 24px;
		transition: 0.6s ease;
		border-radius: 0 3px 3px 0;
		user-select: none;
		&:hover {
			text-decoration: none;
		}
	}
	.next-2 {
		right: 0;
		border-radius: 3px 0 0 3px;
		position: absolute;
		top: 50%;
		width: auto;
		padding: 15px;
		margin-top: -34px;
		color: #7777;
		font-weight: bold;
		font-size: 24px;
		transition: 0.6s ease;
		user-select: none;
		&:hover {
			text-decoration: none;
		}
	}
}

.terms-and-policy {
	font-size: 26px;
	font-weight: 600;
	margin-bottom: 20px;
	text-decoration: none;
	color: black;
	&-hr {
		color: rgba(255, 206, 10, 255) !important;
		border: 5px solid rgba(255, 206, 10, 255);
	}
}

