@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;900&display=swap");
hr {
  margin-top: 0;
  margin-bottom: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
  margin: 0px;
}

p {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0px;
}

.color-red {
  color: #c02b0a;
}

.position-relative {
  position: relative;
}

.w-10 {
  width: 10%;
}
.w-90 {
  width: 10%;
}


.padding-adjust {
  margin-right: 20px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 55px;
}
@media (max-width: 480px) {
  .padding-adjust {
    width: 80%;
    margin-bottom: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .banner-height {
    height: 200px
  }
}

.box-shadow {
  box-shadow: 0 0 9px -6px #000;
  border-radius: 27px;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-margin {
  margin-left: 0px;
  margin-right: 0px;
}

.bold {
  font-weight: bold;
}

.arrow {
  width: 20px;
  padding-left: 4px;
}

.w-20 {
  width: 20%;
}

.w-5 {
  width: 5%;
}

.w-90 {
  width: 90%;
}

.w-30 {
  width: 30%;
}

.h-80 {
  height: 80vh;
}

.form-control:focus {
  border-color: #000000;
  box-shadow: 0 0 0 0;
}

.content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 170px;
  z-index: 1;
  top: 45px;
}

.content .other {
  color: #2b2e34;
  font-size: 13px;
  font-weight: 500;
  padding: 13px 35px 13px 28px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid #ececec;
}

.content .other:hover {
  background-color: #ffce0a;
}

.content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 170px;
  z-index: 1;
  top: 45px;
}

.content .other {
  color: #2b2e34;
  font-size: 13px;
  font-weight: 500;
  padding: 13px 35px 13px 28px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid #ececec;
}

.content .other:hover {
  background-color: #ffce0a;
}

.dropdown:hover .content {
  display: block;
}

.text-alig {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 480px) {
  .text-row {
    flex-direction: row;
    margin-bottom: 30px;
  }
}

.margin-bottom {
  margin-bottom: 111px;
}
@media (max-width: 480px) {
  .margin-bottom {
    margin-bottom: 0px;
  }
}

.text-align {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.bg-yellow900 {
  background-color: #ffce0a;
}

.bg-black900 {
  background-color: #000000;
}

.bg-black800 {
  background-color: #2b2e34;
}

.bg-black700 {
  background-color: #8c8d90;
}

.bg-white {
  background-color: #ffffff;
}

.color-white {
  color: #ffffff;
}

.color-black900 {
  color: #000000;
}

.color-black800 {
  color: #2b2e34;
}

.color-black700 {
  color: #8c8d90;
}

.color-yellow900 {
  color: #ffce0a;
}

.border-white {
  border: 1px solid #ffffff;
}

.border-yellow900 {
  border: 1px solid #ffce0a;
}

.align-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.listing-images-subheading {
  color: rgb(130 130 130);
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 2rem;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}

.show-on-mobile {
  display: none;
}
@media (max-width: 1024px) {
  .show-on-mobile {
    display: block;
  }
}

.hide-on-mobile {
  display: block;
}
@media (max-width: 1024px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .logo-width {
    width: 20%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .logo-width {
    width: 30%;
  }
}

.links-width {
  position: relative;
  display: flex;
}
@media (min-width: 768px) {
  .links-width {
    width: 80%;
  }
}
/* @media (max-width: 1024px) and (min-width: 768px) {
  .links-width {
    width: 30%;
  }
} */

.container-width {
  max-width: 1560px !important;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.header {
  background-color: #000000;
  padding: 20px;
  position: relative;
  z-index: 100;
}
@media (max-width: 600px) {
  .header.responsive {
    position: relative;
  }
}
@media (max-width: 600px) {
  .header {
    padding: 10px 20px 20px 5px;
  }
}
.header .icon {
  display: none;
}
@media (max-width: 1024px) {
  .header .icon {
    display: block;
  }
}
.header .special-class {
  display: none;
}
@media (max-width: 600px) {
  .header .special-class {
    display: none;
  }
}
.header .navbar {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0px;
}
@media (max-width: 600px) {
  .header .navbar {
    padding: 0px;
  }
}
.header-logo-container {
  padding: 10px;
}
.header-logo-container .logo {
  width: 100%;
  max-width: 250px;
}
.header-links {
  margin: 0% 0% 0% 5%;
}
@media (max-width: 600px) {
  .header-links {
    margin-left: 0px;
  }
}
.header-padding {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .header-padding {
    flex-direction: column;
    padding: 0px;
  }
}
.header.responsive .special-class .link-class {
  padding: 13px 0px;
  border: 1px solid #e4e4e4;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  color: #000000;
  border-bottom: 4px solid transparent;
  transition: 0.3s;
  width: 100%;
  text-align: center;
}
.header.responsive .special-class .link-class:hover {
  border-bottom: 4px solid #ffce0a;
  text-decoration: none;
  color: #ffce0a;
}
.header.responsive .special-class .link-class-login {
  padding: 13px 0px;
  margin: 0px 10px;
  width: 100%;
  border: 1px solid #e4e4e4;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  color: #000000;
  transition: 0.3s;
}
@media (max-width: 600px) {
  .header.responsive .special-class {
    background-color: #ffffff;
    display: block;
    position: absolute;
    top: 150px;
    left: 8px;
    right: 0px;
    max-width: 100% !important;
  }
}
.header .login {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .login-link {
  padding: 13px 0px;
  margin: 0px 10px;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffffff;
  border-bottom: 4px solid transparent;
  transition: 0.3s;
}
.header .login-link:hover {
  border-bottom: 4px solid #ffce0a;
  color: #ffce0a;
  text-decoration: none;
}
.header .link {
  padding: 13px 0px 9px 0px;
  margin: 0px 15px;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
  border-bottom: 4px solid transparent;
  transition: 0.3s;
  text-decoration: none;
}
.header .link:hover {
  border-bottom: 4px solid #ffce0a;
  text-decoration: none;
  color: #ffce0a;
}
.header .dropdown {
  display: flex;
}
.header .content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 170px;
  z-index: 1;
  top: 45px;
}
.header .content .other {
  color: #2b2e34;
  font-size: 13px;
  font-weight: 500;
  padding: 13px 35px 13px 28px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid #ececec;
}
.header .content .other:hover {
  background-color: #ffce0a;
}
.header .dropdown:hover .content {
  display: block;
}
.header .active-link {
  border-bottom: 4px solid #ffce0a;
}
.header .empty {
  display: flex;
}

.bg-overlay {
  background-color: transparent;
  opacity: 0.75;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5019607843) 0%,
    #000 100%
  );
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.overlay-adjustment {
  z-index: 5;
  width: 100%;
  position: absolute;
  height: 100%;
}

.home-hero-section {
  position: static;
  padding-bottom: 100px;
  background-size: 1330px;
  background-color: black;
}
.home-hero-section .slideshow-container {
  position: absolute;
  margin: auto;
  width: 100%;
}
.home-hero-section .slideshow-container .fade-img {
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.home-hero-section .home-hero-background-overlay {
  background-color: transparent;
  opacity: 0.75;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5019607843) 0%,
    #000 100%
  );
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.home-hero-section .logo-hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.home-hero-section .logo-hero-section .hero-img {
  text-align: center;
  max-width: 500px;
  width: 100%;
  padding: 0;
}
.home-hero-section .heading {
  font-size: 28px;
  font-weight: 500;
  width: 90%;
  text-transform: uppercase;
  line-height: 1.3em;
  color: #ffce0a;
  padding-top: 40px;
  text-align: center;
  margin-bottom: 10px;
}
.home-hero-section .description-1 {
  text-align: center;
  letter-spacing: normal;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3em;
}
.home-hero-section .description-secondary-text {
  margin-top: 50px;
}
.home-hero-section .description-secondary-text .description-2 {
  font-size: 15px;
  line-height: 25.5px;
  text-align: center;
  letter-spacing: normal;
  color: #ffffff;
}

.advertisement {
  padding: 90px 0px;
}
.advertisement .heading {
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffce0a;
  line-height: 1.2em;
}
.advertisement .slideshows {
  position: relative;
  margin: auto;
}
.advertisement .slideshows .ads {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.advertisement .slideshows .ads .images {
  width: 90%;
}
.advertisement .slideshows .fade-img {
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.advertisement .slideshows .prev {
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #2b2e34;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.advertisement .slideshows .prev:hover {
  text-decoration: none;
}
.advertisement .slideshows .next {
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 3px 0 0 3px;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #2b2e34;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.advertisement .slideshows .next:hover {
  text-decoration: none;
}

.flickity-page-dots {
  display: none;
}

.flickity-button {
  background: transparent !important;
}

.flickity-prev-next-button.next {
  right: -50px !important;
}

.flickity-prev-next-button.previous {
  left: -50px !important;
}

.w3-image {
  width: 100% !important;
}

.section-3 {
  background-color: #ffce0a;
  padding-top: 120px;
  padding-bottom: 90px;
}
.section-3 .heading {
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
  line-height: 1.2em;
  color: #000000;
}

.selling-box {
  background-image: url("/public/images/yellowish_landing_paralax.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  padding: 90px 0px;
}
@media (max-width: 480px) {
  .selling-box {
    padding: 0px;
  }
}
.selling-box .right {
  border-right: 4px solid #ffce0a;
}
@media (max-width: 480px) {
  .selling-box .right {
    border-right: none;
  }
}
.selling-box .left {
  border-left: 4px solid #ffce0a;
}
@media (max-width: 480px) {
  .selling-box .left {
    border-left: none;
  }
}

.button-arrange {
  text-align: center;
}

.selling {
  padding: 40px;
}
@media (max-width: 480px) {
  .selling-line {
    border-bottom: 4px solid #ffce0a;
  }
}
.selling-arrange {
  display: flex;
  padding-top: 90px;
  margin-top: -10px;
  margin-bottom: 90px;
  justify-content: center;
  align-items: center;
}
.selling-heading {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.2em;
  letter-spacing: normal;
  color: #ffffff;
}
.selling-description {
  padding: 21px 52px 41px 52px;
  font-size: 14px;
  line-height: 20.4px;
  letter-spacing: normal;
  color: #ffffff;
}
.selling-button {
  line-height: 1;
  font-size: 15px;
  padding: 12px 24px;
  color: #000000;
  text-decoration: none;
  background-color: #ffce0a;
  font-weight: normal;
  text-transform: uppercase;
  border: 1px solid #ffce0a;
  border-radius: 30px;
  transition: all 0.3s;
}
.selling-button:hover {
  color: white;
  transition: 0.3s;
  text-decoration: none;
}

.section-4 {
  padding: 90px 120px;
}
.section-4 .image-ads {
  height: 260px;
  width: 100%;
}
.section-4 .slideshows {
  position: relative;
  margin: auto;
}
.section-4 .slideshows .ads1 {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.section-4 .slideshows .ads1 .images {
  width: 90%;
}
.section-4 .slideshows .ads1 .images {
  width: 90%;
  height: 55%;
}
.section-4 .slideshows .fade-img1 {
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.section-4 .slideshows .prev {
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #2b2e34;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
}
.section-4 .slideshows .prev:hover {
  text-decoration: none;
}
.section-4 .slideshows .next {
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 3px 0 0 3px;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #2b2e34;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.section-4 .slideshows .next:hover {
  text-decoration: none;
}

.section-5 .flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.section-5 .quotation-box {
  padding: 70px 60px;
  height: 400px;
  max-height: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.section-5 .quotation-box .heading {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
}
.section-5 .quotation-box .heading .heading-span {
  font-size: 26px;
}
.section-5 .quotation-box .description {
  text-align: center;
  font-size: 14px;
  line-height: 1.7;
  margin-top: 20px;
  font-weight: 400;
  margin-bottom: 30px;
  width: 80%;
}
.section-5 .quotation-box .quotation-index {
  z-index: 3;
  position: relative;
}
.section-5 .quotation-box .finance-button {
  text-decoration: none;
  line-height: 1;
  font-size: 15px;
  padding: 12px 24px;
  text-transform: uppercase;
  border-radius: 30px 30px 30px 30px;
  transition: 0.3s;
  font-weight: 500;
}
.section-5 .quotation-box .finance-button1 {
  text-decoration: none;
  line-height: 1;
  font-size: 15px;
  padding: 12px 24px;
  text-transform: uppercase;
  border-radius: 30px 30px 30px 30px;
  transition: 0.3s;
  font-weight: 500;
}
.section-5 .quotation-box .finance-button1:hover {
  color: #ffce0a;
}
.section-5 .quotation-box .finance-button:hover {
  color: #ffffff;
}
.section-5 .quotation-box .finance-button.bg-white:hover {
  background-color: #000000;
}
.section-5 .quotation-box .fin-button:hover {
  color: #ffce0a;
  transition: 0.3s;
}
.section-5 .layout {
  background-color: #2b2e34;
}
.section-5 .layout-light-black {
  background-color: #8c8d90;
}
.section-5 .layout-yellow {
  background-color: #ffce0a;
}
.section-5 .quotation-bg-size {
  background-position: center center;
  background-repeat: repeat;
  background-size: 45% auto;
  width: 100%;
  opacity: 0.2;
}
.section-5 .layout-1 {
  background-image: url("/public/images/hand-finance-money.svg");
}
.section-5 .layout-2 {
  background-image: url("/public/images/delivery-truck.svg");
}
.section-5 .layout-3 {
  background-color: #8c8d90;
  background-image: url("/public/images/construction-mixer.svg");
}

.footer-main {
  background-color: #000000;
  background-attachment: fixed;
  padding-top: 100px;
}
.footer-main .links-main {
  margin-bottom: 40px;
}
.footer-main .links-main .links-heading {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffce0a;
  line-height: 1.2em;
}
.footer-main .links-main .links-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}
.footer-main .links-main .chevron-right {
  width: 14px;
}
.footer-main .links-main .links-tab {
  margin-left: 10px;
  text-decoration: none;
}
.footer-main .links-main .links {
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  letter-spacing: normal;
  font-weight: 400;
}
.footer-main .links-main .links-tab:hover {
  text-decoration: none;
}
.footer-main .footer-heading {
  padding-top: 25px;
  padding-bottom: 25px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2em;
  margin: 0;
  text-align: left;
}
.footer-main .footer-logo {
  width: 185px;
  height: 73.88px;
}
.footer-main .footer-img {
  width: 20px;
  margin-right: 5px;
}
.footer-main .subscribe-btn {
  border-radius: 30px;
  outline: none;
  padding: 10px 26px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-top: 15px;
  font-weight: 500;
  transition: 0.3s;
}
.footer-main .subscribe-btn:hover {
  background-color: #ffce0a;
  color: #ffffff;
}
.footer-main .newsletter {
  width: 70%;
}
.footer-main .copyright-box {
  padding-top: 40px;
  padding-bottom: 20px;
}
@media (max-width: 480px) {
  .footer-main .copyright-box {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.footer-main .copyright-box .copyright-text {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
}
@media (max-width: 480px) {
  .footer-main .copyright-box .copyright-text {
    font-size: 10px;
    padding: 11px;
  }
}

.footer-space {
  margin-top: 80px;
}
@media (max-width: 480px) {
  .footer-space {
    margin-top: 27px;
  }
}

@media (max-width: 480px) {
  .home-hero-section .heading {
    font-size: 16px;
  }
  .home-hero-section .description-1 {
    font-size: 15px;
  }
  .home-hero-section .description-secondary-text {
    margin-top: 25px;
  }
  .home-hero-section .description-secondary-text .description-2 {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .advertisement {
    padding: 20px 0px;
  }
  .advertisement .heading {
    font-size: 16px;
  }
  .carousel-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .w3-image {
    width: 90% !important;
  }
  .flickity-prev-next-button.previous {
    left: -10px !important;
  }
  .flickity-prev-next-button {
    width: 30px !important;
    height: 30px !important;
  }
  .flickity-prev-next-button.next {
    right: -10px !important;
  }
  .section-3 {
    padding-top: 35px;
    padding-bottom: 30px;
  }
  .section-3 .heading {
    font-size: 24px;
  }
  .section-4 {
    padding: 20px;
  }
  .section-4 .last-one {
    margin-top: 20px;
  }
  .section-5 .quotation-box {
    padding: 50px 0px;
    height: 280px;
    max-height: 300px;
  }
  .section-5 .quotation-box .heading {
    font-size: 24px;
  }
  .section-5 .quotation-box .heading .heading-span {
    font-size: 16px;
  }
  .section-5 .quotation-box .description {
    font-size: 14px;
    width: 80%;
  }
  .footer-main {
    padding-top: 42px;
  }
  .footer-main .footer-logo {
    margin-bottom: 40px;
  }
  .footer-main .footer-heading {
    display: none;
  }
  .footer-main .hide-on-mobile {
    display: none;
  }
  .footer-main .special-width {
    width: 50%;
  }
  .footer-main .newsletter {
    width: 100%;
  }
  .footer-main .subscribe-btn {
    font-size: 12px;
  }
  .footer-main .links-main .links-heading {
    font-size: 13px;
  }
  .footer-main .links-main .links {
    font-size: 12px;
  }
  .footer-main .links-main .chevron-right {
    width: 12px;
  }
}
.selling-home-section {
  background-image: url("/public/images/yellowish-banner-bw-2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.selling-home-section .sell {
  padding: 130px 90px 130px 90px;
}
@media (max-width: 480px) {
  .selling-home-section .sell {
    padding: 40px 20px;
  }
}
.selling-home-section .sell-heading {
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
}
@media (max-width: 480px) {
  .selling-home-section .sell-heading {
    font-size: 33px;
  }
}
.selling-home-section .sell-line {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 8px;
  position: relative;
  width: 50px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@media (max-width: 480px) {
  .selling-home-section .sell-line {
    height: 5px;
  }
}
.selling-home-section .sell-paragraph {
  width: 44%;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
  letter-spacing: normal;
}
@media (max-width: 480px) {
  .selling-home-section .sell-paragraph {
    width: 100%;
    font-size: 10px;
    line-height: 140%;
  }
}

.sell-sec2-heading1 {
  font-weight: 600;
  line-height: 1.2em;
  font-size: 1.25rem;
  padding-bottom: 20px;
}
@media (max-width: 480px) {
  .sell-sec2-heading1 {
    font-size: 14px;
    line-height: 1.2em;
    padding-bottom: 10px;
  }
}
.sell-sec2-heading2 {
  font-weight: 600;
  line-height: 1.2em;
  font-size: 1.375rem;
}
@media (max-width: 480px) {
  .sell-sec2-heading2 {
    font-size: 16px;
    line-height: 1.2em;
  }
}
.sell-sec2-bottom-line {
  margin-top: 20px;
  padding: 3.5px;
  position: relative;
  width: 33.5%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@media (max-width: 480px) {
  .sell-sec2-padd {
    padding: 20px 20px 40px 20px;
  }
}
.sell-sec2-align {
  text-align: center;
  font-weight: 600;
  line-height: 33.6px;
  font-size: 28px;
  padding-top: 100px;
  padding-bottom: 50px;
}
@media (max-width: 480px) {
  .sell-sec2-align {
    font-size: 18px;
    padding-right: 59px;
    padding-left: 60px;
    padding-top: 0px;
    line-height: 1.2;
    padding-bottom: 35px;
  }
}
.sell-sec2-paragraph {
  margin-top: 60px;
  font-size: 14px;
  line-height: 1.7;
  margin-bottom: 1.5em;
  text-align: center;
  font-weight: 400;
}
@media (max-width: 480px) {
  .sell-sec2-paragraph {
    font-size: 13px;
    margin-top: 25px;
  }
}
.sell-sec2-button {
  text-align: center;
  display: inline-block;
  border-radius: 30px 30px 30px 30px;
  text-decoration: none !important;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  padding: 15px 30px;
  width: auto;
  transition: 0.3s;
}
@media (max-width: 480px) {
  .sell-sec2-button {
    font-size: 12px;
    padding: 10px 20px;
  }
}
.sell-sec2-button:hover {
  background-color: white;
  color: #ffce0a;
}
.sell-sec2-justify {
  text-align: justify;
}
.sell-sec2-justify::after {
  content: "";
  width: 100%;
  display: inline-block;
}

#quote-sec-1 {
  background-image: url("/public/images/yellowish-banner-bw-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 90px 130px 90px;
}
#quote-sec-1 .quote-heading {
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
}
#quote-sec-1 .bottom-line {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  position: relative;
  width: 8.5%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
#quote-sec-1 .quote-paragraph {
  margin: 0% 44% 0% 0%;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
  letter-spacing: normal;
}

.cont1 {
  padding-top: 60px;
}
@media (max-width: 480px) {
  .cont1 {
    padding-top: 20px;
  }
}
.cont1-logo {
  width: 185px;
  height: 73.88px;
}
@media (max-width: 480px) {
  .cont1-logo {
    display: none;
  }
}
.cont1-image {
  width: 20px;
  margin-right: 5px;
}
.cont1-textalign {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.cont1-heading1 {
  padding-bottom: 32px;
  font-size: 45px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
  padding-top: 85px;
}
@media (max-width: 480px) {
  .cont1-heading1 {
    font-size: 26px;
  }
}
.cont1-anker {
  padding-bottom: 2px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
.cont1-anker1 {
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
.cont1-anker:hover {
  color: white;
  text-decoration: none;
}
.cont1-anker1:hover {
  color: #ffce0a;
  text-decoration: none;
}
.cont1-padding {
  padding: 80px;
}
@media (max-width: 480px) {
  .cont1-padding {
    padding: 40px 30px 40px 30px;
    align-content: center;
    align-items: center;
  }
}
.cont1-padd {
  padding-top: 25px;
  padding-bottom: 55px;
}
@media (max-width: 480px) {
  .cont1-padd {
    padding-bottom: 10px;
  }
}
.cont1-bgcontact {
  background-image: url("/public/images/contact_Us_Image_comp-scaled.jpg");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 480px) {
  .cont1-bgcontact {
    background-image: none;
  }
}
.cont1-button {
  font-weight: 500;
  margin-top: 10px;
  margin-left: 46px;
  text-align: center;
  display: inline-block;
  border-radius: 30px 30px 30px 30px;
  text-decoration: none !important;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  padding: 15px 30px;
  width: auto;
  transition: 0.3s;
}
@media (max-width: 480px) {
  .cont1-button {
    font-size: 12px;
    padding: 13px 30px 12px 30px;
    margin-left: 25px;
  }
}
.cont1-nopadding {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: start;
}
@media (max-width: 480px) {
  .cont1-nopadding {
    padding: 20px 20px 20px 20px;
  }
}
.cont1-right {
  right: 21%;
  z-index: 6;
}
@media (max-width: 480px) {
  .cont1-right {
    right: 0px;
  }
}
.cont1-flexst {
  margin-left: 46px;
}
@media (max-width: 480px) {
  .cont1-flexst {
    margin-left: 30px;
  }
}
.cont1-attach {
  padding: 40px 10px 40px 0px;
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  .cont1-attach {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.cont1-button:hover {
  background-color: white;
  border-color: white;
  color: #ffce0a;
  border: 1px solid #ffce0a;
}
.cont1-heading2 {
  font-size: 14px;
  padding-bottom: 26px;
  line-height: 1.7;
  font-weight: 700;
  text-transform: uppercase;
  flex: content;
}
.cont1-para {
  margin-bottom: 21px;
  line-height: 1.7;
  font-size: 12px;
}
.cont1-inputBox {
  margin-bottom: 20px;
  padding: 15px 0px 15px 20px;
  width: 82%;
  font-size: 15px;
  float: none;
  height: auto;
  border: 1px solid #e4d4e4;
}
@media (max-width: 480px) {
  .cont1-inputBox {
    padding: 10px 0px 10px 20px;
    font-size: 13px;
    border-radius: 0;
    line-height: 2;
    min-height: 32px;
  }
}

/* #rental-sec1 {
  background-image: url("/public/images/yellowish-banner-bw-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 90px 130px 90px;
}
yellowish-banner-bw-1 */
#rental-sec1 {
  background-image: url("/public/images/yellowish-banner-bw-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 90px 130px 90px;
}
@media (max-width: 480px) {
  #rental-sec1 {
    padding: 40px 20px;
  }
}
#rental-sec1 .rental-heading {
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
}
@media (max-width: 480px) {
  #rental-sec1 .rental-heading {
    font-size: 33px;
  }
}
#rental-sec1 .bottom-line {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  position: relative;
  width: 8.5%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@media (max-width: 480px) {
  #rental-sec1 .bottom-line {
    margin-top: 8px;
    margin-bottom: 12px;
    padding: 3px;
    width: 15.5%;
  }
}
#rental-sec1 .rental-paragraph {
  margin: 0% 44% 0% 0%;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
  letter-spacing: normal;
}
@media (max-width: 480px) {
  #rental-sec1 .rental-paragraph {
    font-size: 10px;
    margin: 0%;
    line-height: 1.7;
  }
}

.rental-sec2-align {
  text-align: center;
  font-weight: 600;
  line-height: 33.6px;
  font-size: 28px;
  padding-top: 100px;
  padding-bottom: 50px;
}
@media (max-width: 480px) {
  .rental-sec2-align {
    margin: 40px 40px 40px 40px;
    padding: 40px 40px 40px 40px;
    font-size: 1.75rem;
  }
}

.finq1 {
  padding: 10px 10px 10px 10px;
  display: flex;
}
@media (max-width: 600px) {
  .finq1 {
    display: flex;
    padding: 20px 10px 10px 10px;
    flex-direction: column-reverse;
  }
}
.finq1-ads {
  padding: 10px;
  width: 25%;
}
@media (max-width: 600px) {
  .finq1-ads {
    width: 100%;
    display: flex;
    padding: 5px 7px 5px 5px;
  }
}
.finq1-text-area {
  padding: 90px;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 600px) {
  .finq1-text-area {
    width: 100%;
    padding: 30px;
  }
}
.finq1-text-area .info {
  margin-bottom: 1.5em;
  line-height: 1.7;
  font-size: 14px;
  color: #2b2e34;
  text-align: center;
}
@media (max-width: 600px) {
  .finq1-text-area .info {
    font-size: 12px;
    margin: 0px 20px;
  }
}
.finq1 .text-box {
  text-align: center;
  width: 100%;
}
.finq1 .text-box-box {
  padding: 0 10% 0 10%;
  margin: 0px 0px 5px 0px;
}
@media (max-width: 600px) {
  .finq1 .text-box-box {
    padding: 0%;
  }
}
.finq1 .text-box-heading {
  color: #000000;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3em;
  margin: 0px;
  text-align: center;
}
@media (max-width: 600px) {
  .finq1 .text-box-heading {
    font-size: 16px;
  }
}
.finq1 .ads-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 600px) {
  .finq1 .ads-container {
    display: flex;
    flex-direction: row;
  }
}
.finq1 .ads-box-2 {
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .finq1 .ads-box-2 {
    padding: 5px 7px 5px 5px;
    margin-bottom: 0px;
  }
}
.finq1-direction-reverse {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.finq1-heading {
  margin-top: 20px;
  vertical-align: middle;
  display: inline-block;
  height: auto;
  width: 100%;
  aspect-ratio: auto 600/600;
}
@media (max-width: 480px) {
  .finq1-heading {
    margin: 0;
    padding: 5px 7px 5px 5px;
  }
}
.finq1-head {
  padding-bottom: 25px;
  font-size: 32px;
  font-weight: 550;
  line-height: 1.3em;
  text-align: center;
}
@media (max-width: 480px) {
  .finq1-head {
    font-size: 16px;
  }
}
.finq1-padding {
  padding: 80px 80px 80px 80px;
}
@media (max-width: 480px) {
  .finq1-padding {
    padding: 40px;
  }
}
.finq1-para {
  text-align: center;
  font-size: 14px;
  margin-bottom: 1.5em;
  line-height: 1.7;
  font-weight: 400;
}
@media (max-width: 480px) {
  .finq1-para {
    font-size: 12px;
  }
}
.ds-tab {
  padding-left: 27px;
  padding-right: 27px;
  border: 1px solid #e4e4e4;
  border-top: 0px;
  margin: 0 0 2em;
}
.ds-tab .ds-heading {
  font-size: 1.125rem;
  line-height: 2;
  font-weight: 700;
  color: #ffce0a;
  font-family: "Open Sans", sans-serif !important;
  word-wrap: break-word;
  margin-bottom: 28px;
  margin-top: 0.5rem;
}
.active-class {
  border-top: 3px solid #ffce0a !important;
}

.active-class-2 {
  background-color: #ffffff !important;
  border-top: 3px solid #ffce0a !important;
  border-bottom: 1px solid white !important;
}
.active-class-2 .form-action {
  color: #777777 !important;
}
.form {
  padding-bottom: 30px;
}
.form .tabs-main {
  width: 100%;
  margin-bottom: 1.618em;
  position: relative;
  padding: 0px 0px 0px 10px;
}
.form .tabs-main .border-line {
  width: 100%;
}

.error-text-color {
  color: #d12d37 !important;
}

.simple-border-line {
  width: 100%;
}
.simple-border-line::before {
  border-bottom: 1px solid #dddddd;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  content: "";
}

.form .tabs-main .border-line::before {
  border-bottom: 1px solid #dddddd;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  content: "";
}
.form-bt1 {
  z-index: 2;
  background-color: #f4f4f4;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  display: inline-block;
  position: relative;
  padding: 0 1em;
  outline: 0;
  font-size: 100%;
}
.form-action {
  padding: 0.3em 0px 0.5em 0px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif !important;
  color: #444;
  font-weight: 700;
}
.form-head {
  line-height: 1.2em;
  font-size: 1.375rem;
  margin-bottom: 28px;
  font-weight: 500;
  margin-top: 0.5rem;
  font-family: "Open Sans", sans-serif !important;
}
.form-para {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1.5em;
  line-height: 1.7;
  margin-top: 0%;
  color: #777777;
  font-family: "Open-Sans", sans-serif !important;
}
.form-input {
  border: 1px solid rgba(234, 234, 234, 0.9176470588);
  font-size: 14px;
  margin: 0 0 5px;
  padding: 8px;
  width: 300px;
  color: #888;
  border-radius: 3px;
  transition: all 0.3s;
  overflow: visible;
}

.form-input-border {
  border: "2px solid #F1F1F1";
}

.add-listing-label {
  font-weight: bold !important;
  color: #777777 !important;
}

.input-info-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0px;
  margin-top: 4px;
  color: #7a888f;
}

.form-bt2 {
  background: #fbce0a none repeat scroll 0 0;
  border: 2px solid #fbce0a;
  border-radius: 0;
  box-shadow: none;
  display: inline-block;
  font-size: 14px;
  height: auto;
  line-height: unset;
  padding: 7px 26px;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: normal;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 1.5em;
}
.form-bt2:hover {
  background-color: white;
  color: black;
}
.form-inline {
  display: inline-block;
  width: 180px;
}

.transport-sec1 {
  background-image: url("/public/images/yellowish-banner-bw-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 90px 130px 90px;
}
@media (max-width: 480px) {
  .transport-sec1 {
    padding: 40px 29px;
  }
}
.transport-sec1 .transport-heading {
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
}
@media (max-width: 480px) {
  .transport-sec1 .transport-heading {
    font-size: 33px;
  }
}
.transport-sec1 .bottom-line {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  position: relative;
  width: 8.5%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@media (max-width: 480px) {
  .transport-sec1 .bottom-line {
    margin-top: 8px;
    margin-bottom: 12px;
    padding: 3px;
    width: 15.5%;
  }
}
.transport-sec1 .transport-paragraph {
  margin: 0% 44% 0% 0%;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
  letter-spacing: normal;
}
@media (max-width: 480px) {
  .transport-sec1 .transport-paragraph {
    font-size: 10px;
    margin: 0%;
    line-height: 1.7;
  }
}

.quote-sec1 {
  background-image: url("/public/images/yellowish-banner-bw-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 90px 130px 90px;
}
@media (max-width: 480px) {
  .quote-sec1 {
    padding: 40px 29px;
  }
}
.quote-sec1 .quote-heading {
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
}
@media (max-width: 480px) {
  .quote-sec1 .quote-heading {
    font-size: 33px;
  }
}
.quote-sec1 .bottom-line {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  position: relative;
  width: 8.5%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@media (max-width: 480px) {
  .quote-sec1 .bottom-line {
    margin-top: 8px;
    margin-bottom: 12px;
    padding: 3px;
    width: 15.5%;
  }
}
.quote-sec1 .quote-paragraph {
  margin: 0% 44% 0% 0%;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
  letter-spacing: normal;
}
@media (max-width: 480px) {
  .quote-sec1 .quote-paragraph {
    font-size: 10px;
    margin: 0%;
    line-height: 1.7;
  }
}

.buying-section {
  padding: 120px 0px 60px 0px;
}
.buying-section .search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
@media (max-width: 480px) {
  .buying-section .search {
    display: block;
  }
}

.buying-section .category-border {
  border: 1px solid #ffce0a;
  padding-top: 40px;
  /* margin: 15px; */
}

.buying-section .search-input {
  border-radius: 4px;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #aaa;
  height: 40px;
  background-color: #ffffff;
  padding: 5px 15px;
  color: #444;
  outline: none;
  flex-grow: 2;
  transition: 0.3s;
}
@media (max-width: 480px) {
  .buying-section .search-input {
    width: 100%;
  }
}
.buying-section .search-input:focus-visible {
  outline: none;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #ffce0a;
}
.buying-section .search-button {
  background-color: #ffce0a;
  padding: 7px 26px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
  border: 2px solid #ffce0a;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 15px;
  margin-left: 5px;
}
@media (max-width: 480px) {
  .buying-section .search-button {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 40px;
  }
}
.buying-section .display-table {
  display: flex;
  flex-wrap: wrap;
}
.buying-section .listing-main {
  margin-top: 40px;
  width: 100%;
  transition: 0.3s;
}
.buying-section #btnContainer {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
@media (max-width: 480px) {
  .buying-section #btnContainer {
    display: none;
  }
}
.buying-section #btnContainer .grid-button {
  border: 1px solid #e4e4e4;
  border-radius: 100%;
  padding: 13px 16px;
  margin: 0 7px 0 0;
  cursor: pointer;
  background-color: #ffffff;
}
.buying-section .listing {
  border: 1px solid #e4e4e4;
  display: flex;
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 800px) {
  .buying-section .listing {
    display: block;
  }
}
.buying-section .listing-img {
  width: 39%;
  overflow: hidden;
}
@media (max-width: 800px) {
  .buying-section .listing-img {
    width: 100%;
  }
}
.buying-section .listing-container {
  width: 100%;
}
.buying-section .listing-image {
  width: 100%;
  height: 380px;
}
.buying-section .listing .summary {
  transition: 0.3s;
  margin-left: 40px;
  position: relative;
}
@media (min-width: 8010px) {
  .buying-section .listing .summary {
    width: 58%;
  }
}
@media (max-width: 800px) {
  .buying-section .listing .summary {
    margin-left: 0px;
    padding: 0 15px 56px 15px;
  }
  .buying-section .listing-image {
    height: auto;
  }
}
.buying-section .listing .summary-title {
  margin-top: 45px;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 15px;
  font-family: "Open Sans", sans-serif !important;
  text-align: left;
}
.buying-section .listing .summary-title .anchor {
  color: #3d414e;
  text-decoration: none;
  transition: 0.3s;
}
.buying-section .listing .summary-title .anchor:hover {
  color: #ffce0a;
}
.buying-section .listing .summary-price {
  margin-bottom: 4px;
  font-size: 1.625rem;
  line-height: 1.4;
  color: #3d414e;
  font-weight: 700;
  font-family: "Open Sans", sans-serif !important;
}
.buying-section .listing .summary-price-currency {
  margin-right: 5px;
}
.buying-section .listing .summary-glance {
  position: absolute;
  bottom: 62px;
  right: 224px;
  background-color: #f8f8f8;
  width: 39%;
  margin-top: 20px;
  display: flex;
}
.width-maxcontent {
  width: max-content;
}
.width-100 {
  width: 100%;
}
.buying-section .listing .summary-glance .summary-border {
  padding: 14px 30px 11px;
  border-right: 1px solid #e4e4e4;
  display: flex;
}

@media (max-width: 800px) {
  .buying-section .listing .summary-glance {
    position: relative;
    top: revert;
    left: 0px;
    bottom: revert;
    margin-top: 0px;
    width: 100%;
    background-color: transparent;
    border-right: none;
    border-bottom: 1px solid #e4e4e4;
  }
}
.buying-section .listing .summary-glance .box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
}
.buying-section .listing .summary-glance .box-span {
  font-size: 0.75rem;
  color: #555555;
  font-weight: 700;
  text-align: center;
  margin: 0px;
}
.buying-section .listing .contents {
  padding-left: 40px;
}
@media (max-width: 480px) {
  .buying-section .listing .contents {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 40px;
  }
}
.buying-section .listing .contents .content {
  margin-top: 45px;
  padding: 15px;
}
.buying-section .listing .contents .content-title {
  color: #3d414e;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
  transition: 0.3s;
}
.buying-section .listing .contents .content-title:hover {
  color: #ffce0a;
}
@media (max-width: 480px) {
  .buying-section .listing .contents .content {
    padding-top: 105px;
    margin-top: 0px;
  }
}
.buying-section .listing .contents-price {
  margin-bottom: 4px;
  font-size: 1.25rem;
  color: #3d414e;
  font-weight: 600;
  transition: 0.3s;
}
.buying-section .listing .contents-meter {
  background-color: #f8f8f8;
  border-right: 1px solid #e4e4e4;
  width: 100%;
  padding: 14px 30px 11px;
  margin-top: 20px;
}
@media (max-width: 800px) {
  .buying-section .listing .contents-meter {
    position: absolute;
    top: 0px;
    left: 0px;
    margin-top: 0px;
    background-color: transparent;
    border-right: none;
    border-bottom: 1px solid #e4e4e4;
  }
}
.buying-section .listing .contents-meter .box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
}
.buying-section .listing .contents-meter .box-span {
  font-size: 0.75rem;
  color: #555555;
  font-weight: 700;
  text-align: center;
  margin: 0px;
}
.buying-section .listing .wrap {
  position: absolute;
  right: 20px;
  bottom: -20px;
}
.buying-section .listing .wrap-button {
  background-color: #ffce0a;
  margin-top: 20px;
  transition: 0.3s;
  color: #2b2e34;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid #ffce0a;
  padding: 10px 18px;
  margin-right: 14px;
  text-decoration: none;
}
.buying-section .listing .wrap-button:hover {
  background-color: #ffffff;
  text-decoration: none;
}
.buying-section .listing-new {
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: #5ba533;
  text-transform: uppercase;
  padding: 9px 10px;
  font-size: 0.75rem;
  border-radius: 4px;
  font-weight: 700;
  z-index: 20;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.buying-section .listing-new .star {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}
.buying-section .listing-sale {
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: #ffce0a;
  color: #000000;
  font-size: 12px;
  font-weight: 300;
  padding: 5px 10px;
  margin: 0;
  line-height: 12px;
}
.buying-section .listing-sale-speed {
  height: 13px;
  width: 13px;
  margin-right: 5px;
}
.buying-section .pagination {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media (max-width: 480px) {
  .buying-section .pagination {
    margin-top: 50px;
  }
}
.buying-section .pagination-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.buying-section .pagination-btns {
  border: 2px solid #ddd;
  padding: 0.5em 0.75em;
  border-radius: 10px;
  margin-right: 10px;
  font-weight: 500;
  color: #2b2e34;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
@media (max-width: 480px) {
  .buying-section .pagination-btns {
    font-size: 11px;
  }
}
.buying-section .pagination-btns:hover {
  color: #ffffff;
  background-color: #ffce0a;
  border-color: #ffce0a;
  text-decoration: none;
}
.buying-section .pagination-active {
  color: #ffffff;
  background-color: #ffce0a;
}
.buying-section .pagination-next {
  cursor: pointer;
  transition: 0.3s;
  padding: 12px 14px;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-right: 10px;
  text-decoration: none;
  color: #000;
  text-decoration: none !important;
}
.buying-section .pagination-next:hover {
  color: #ffffff;
  background-color: #ffce0a;
  border-color: #ffce0a;
  text-decoration: none !important;
}
@media (max-width: 480px) {
  .buying-section .pagination-next {
    padding: 10px;
    text-decoration: none !important;
  }
}

.no-decoration:hover {
  text-decoration: none;
}

.category {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
  /* cursor: pointer; */
  margin-bottom: 40px;
  text-decoration: none;
}
.category-circle {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 120px;
  border-radius: 50%;
  height: 120px;
  transition: 0.3s;
}
.category-heading {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0px;
  color: #ffce0a;
  text-align: center;
  margin-top: 20px;
}
.category-description {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #000000;
  text-align: center;
  margin-top: 10px;
  width: 85%;
}
.category-button {
  margin-top: 30px;
  border: 1px solid #ffce0a;
  background-color: #ffffff;
  padding: 10px 24px;
  border-radius: 35px;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.3s;
  text-decoration: none !important;
}
.category:hover {
  text-decoration: none;
}
.category:hover .category-circle {
  background-color: #ffce0a;
}
.category:hover .category-button {
  background-color: #ffce0a !important;
  color: #000000;
}

.detail {
  width: 100%;
  position: relative;
}

.tables {
  margin-top: 40px;
}
.tables-heading {
  font-size: 1.625rem;
  color: #3d414e;
  font-weight: 700;
  line-height: 1.4;
  text-align: left;
  margin-top: 12px;
}
.tables-info {
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  margin-bottom: 1.5rem;
}
table td {
  border: none;
}
table td,
table th {
  text-align: left;
  font-family: "Open Sans", sans-serif !important;
}
.table th {
  padding-left: 27px;
}
.table th,
.table td {
  border-left: 0px;
  border-right: 0px;
  border: none !important;
  font-size: 0.8125rem;
  font-weight: 600;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f7f7f7 !important;
}
.table tr {
  border-bottom: 1px solid #e4e4e4;
}
.sidebar {
  border: 10px solid #f8f8f8;
  width: 100%;
}
.sidebar-box {
  padding: 16px 30px 30px;
  width: 100%;
  display: flex;
}
.sidebar-box-button {
  width: 100%;
  background-color: #ffce0a;
  border: 2px solid #fbce0a;
  padding: 7px 26px;
  color: #000 !important;
  font-size: 14px;
  line-height: unset;
  margin: 0;
  text-align: center;
  transition: 0.3s;
  text-decoration: none;
}
.sidebar-box-button:hover {
  text-decoration: none;
  background-color: #ffffff;
}

.tab {
  overflow: hidden;
  border-bottom: 1px solid #e4e4e4;
  background-color: #ffffff;
}
.tab .tabs-class {
  background-color: #f4f4f4;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid transparent;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}
.tab .tabs-class:hover {
  background-color: #ddd;
}
.tab .tabs-class.active {
  background-color: #ffffff;
  border-top: 2px solid #ffce0a;
  border-bottom: 1px solid #ffffff;
}

.tabcontent {
  /* display: none; */
}
.tabcontent .tab-heading {
  font-size: 1.125rem;
  line-height: 2;
  font-weight: 700;
  color: #ffce0a;
  margin-bottom: 28px;
  margin-top: 0, 5rem;
}

.slideshows {
  position: relative;
  margin: auto;
}
.slideshows .ads {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.slideshows .ads .images {
  width: 90%;
}
.slideshows .fade-img {
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.slideshows .prev {
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #2b2e34;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slideshows .prev:hover {
  text-decoration: none;
}
.slideshows .next {
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 3px 0 0 3px;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #2b2e34;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slideshows .next:hover {
  text-decoration: none;
}

.slideshows-2 {
  position: relative;
  margin: auto;
  height: 100%;
}
.slideshows-2 .ads-tracking {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.slideshows-2 .ads-tracking .images-2 {
  width: 100%;
  height: 100%;
}
.slideshows-2 .fade-img-2 {
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.slideshows-2 .prev-2 {
  position: absolute;
  top: 50%;
  width: auto;
  padding: 12px;
  margin-top: -32px;
  color: rgba(119, 119, 119, 0.4666666667);
  font-weight: bold;
  font-size: 24px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slideshows-2 .prev-2:hover {
  text-decoration: none;
}
.slideshows-2 .next-2 {
  right: 0;
  border-radius: 3px 0 0 3px;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 15px;
  margin-top: -34px;
  color: rgba(119, 119, 119, 0.4666666667);
  font-weight: bold;
  font-size: 24px;
  transition: 0.6s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slideshows-2 .next-2:hover {
  text-decoration: none;
} /*# sourceMappingURL=index.css.map */

.terms-and-policy-column {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  text-decoration: none;
  color: black;
}

.terms-and-policy-underline {
  color: rgba(255, 206, 10, 255) !important;
  border: 5px solid rgba(255, 206, 10, 255);
}

/*     *******************************For policy terms """"""""""""""""""""""""""""""""""""""""""""""""""  */

@media all {
  ::-ms-reveal {
    display: none;
  }
}
@media all {
  .elementor-widget-text-editor p {
    margin-top: 0;
    margin-bottom: 0.9rem;
  }

  li,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-family: "Poppins", sans-serif !important;
  }
}
@media all {
  .elementor-widget-text-editor ol,
  .elementor-widget-text-editor ul {
    margin-bottom: 15px;
  }
  .elementor-widget-text-editor ol {
    padding-left: 15px !important;
  }
}



@media (max-width: (800px)) {
    .listing-width {
      width: 100% !important;
    }
    .listing-css {
      display: block;
    }
    .lisitng-img {
      width: 100% !important;
    }

    .listing-summary {
      width: 100%;
			margin-left: 0px;
			padding: 20px 30px;
    }
}
